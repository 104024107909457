body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url(./fonts/RobotoMono-Regular.ttf) format('truetype');
}

::-webkit-scrollbar {
  width: 10px!important;
}

.ag-dnd-ghost {
  color: white;
  background-color:#134268;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 1px;
}